<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth="'1000px'">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.name || "Информация о товаре" : "Новая запись товара" }}
      </template>
      <v-row>
        <v-col cols="12" md="5" class="px-2">
          <a-form-model
            v-if="editMode"
            :readonly="true"
            v-model="data"
            :model="model"
            :errors="errors"
            :config="{ dense: true }"
            @validate="validate($event)"
          />
          <div v-else>
            <a-form-view v-model="data" :model="model" :config="{ dense: true }" />
            <a-view-images :value="data.photos" />
          </div>
        </v-col>
        <v-col cols="12" md="7" class="px-2">
          <v-card-text style="height: 300px; overflow: auto">
            <a-table-f-api
              ref="table"
              :api="url"
              :model="modelAccum"
              :searchable="false"
              :useQuery="false"
              :defaults="{ filters, sort: { key: 'date_doc', order: 'DESC' } }"
              @click="onClickRow($event)"
            />
          </v-card-text>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    showOwner: Number,
    object_id: Number,
    id: Number,
    idStore: Number,
  },
  data() {
    return {
      model: this.$store.getters["config/get"].models.storageGoods.form,
      api: "/accounting/dir/good",
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить этот товар?",
      url: "/accounting/accum_reserve",
      idEdit: 0,
      suEditShow: false,
      editMode: false,
    };
  },
  computed: {
    filters() {
      let filter = { store_id: this.idStore, good_id: this.id };
      if (this.showOwner == 1) filter.object_id = this.object_id;
      return filter;
    },

    canEdit() {
      return this.getAccess("storageGoods.edit");
    },
    modelAccum() {
      let model = [
        { name: "id", title: "#", type: "id", sortable: true, width: 50 },
        { name: "date_doc", title: "Дата", type: "datetime", sortable: true, width: 120 },
        { name: "amount", title: "Кол.", type: "number", sortable: true, width: 50 },
        { name: "value", title: "Цена.", type: "number", sortable: true, width: 50 },
        {
          name: "base_id",
          title: "документ",
          type: "namedoc",

          itemField: { value: "id", text: "code_doc" },
          doc_name: { key: "base_name" },
          doc_id: { key: "base_id" },
          width: 200,
        },
      ];
      return model;
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.editMode = false;
        this.updateData(this.id);
        if (!this.id) {
          this.editMode = !true;
          //  this.data.status = 0;
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.editMode = false;
      this.updateData(this.id);
      if (!this.id) {
        this.editMode = !true;
      }
    }
  },
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>